.Grid {
  padding-top: 20px;

  .tile {
    width: 400px;
    height: 400px;
    margin-left: 20px;
    margin-bottom: 20px;
    float: left;
  }
}